//const BaseUrl="http://127.0.0.1:8000/api/"
//const BaseUrl="http://5.161.75.175/api/"
export const BaseUrl="https://spcenter.cl/api/"

export const LoginUrl = BaseUrl+ "user/login"
export const MeUrl = BaseUrl + "user/Me"
export const CreateUserUrl = BaseUrl + "user/create-user"
export const UsersUrl = BaseUrl + "user/users"
export const managersUrl = BaseUrl + "user/managers"
export const buyersUrl = BaseUrl + "user/buyers"
export const cecoUrl = BaseUrl + "user/cecoownerView"
export const requestersUrl = BaseUrl + "user/reqeusters"
export const UpdatePasswordUrl = BaseUrl + "user/update-password"
export const ForgotPasswordUrl = BaseUrl + "user/ForgotPasswordView"
export const ResetPasswordUrl = BaseUrl + "user/ResetPasswordView"

export const categoriesUrl = BaseUrl + "masterdata/categories"
export const unitofmeasuresUrl = BaseUrl + "masterdata/unitofmeasures"
export const currenciesUrl = BaseUrl + "masterdata/currencies"
export const locationsUrl = BaseUrl + "masterdata/locations"
export const projectsUrl = BaseUrl + "masterdata/projects"
export const suppliersUrl = BaseUrl + "masterdata/suppliers"
export const productsUrl = BaseUrl + "masterdata/products"


export const ChatbotUrl = BaseUrl + "transactiondata/Chatbot"
export const PChatbotUrl = BaseUrl + "procurement/Chatbot/"


export const transferorderfilesUrl = BaseUrl + "transactiondata/transferorderfiles"
export const purchaseorderfilesUrl = BaseUrl + "transactiondata/purchaseorderfiles"
export const purchaseorderUrl = BaseUrl + "transactiondata/purchaseorder"
export const stocktransferorderUrl = BaseUrl + "transactiondata/stocktransferorder"
export const onhandUrl = BaseUrl + "transactiondata/onhandinventory"
export const InventoryUrl = BaseUrl + "transactiondata/InventoryViewSet"
export const InventoryMD04Url = BaseUrl + "transactiondata/InventoryViewSetMD04"


export const shipmentURL = BaseUrl + "transactiondata/shipmentView"
export const shipmentbulkURL = BaseUrl + "transactiondata/shipmentView/bulk_delete"
export const purchasebulkURL = BaseUrl + "transactiondata/purchaseorder/bulk_delete"

export const demmurageURL = BaseUrl + "transactiondata/demurragecontrolView"

export const sendstoUrl = BaseUrl + "transactiondata/stocktransferorder/send_email_transfer_order"
export const viewstoUrl = BaseUrl + "transactiondata/stocktransferorder/view_transfer_order"


export const sendpoUrl = BaseUrl + "transactiondata/purchaseorder/send_email_purchase_order"
export const viewpoUrl = BaseUrl + "transactiondata/purchaseorder/view_purchase_order"


export const GroupViewUrl = BaseUrl + "user/GroupView"
export const GroupAddViewUrl = BaseUrl + "user/GroupAddView"
export const GroupDeleteViewUrl = BaseUrl + "user/GroupDeleteView"

export const GroupLocationAddViewUrl = BaseUrl + "user/GroupLocationAddView"
export const GroupProjectAddViewUrl = BaseUrl + "user/GroupProjectAddView"
export const UserGroupAddViewUrl = BaseUrl + "user/UserGroupAddView"

export const InventorySummaryUrl = BaseUrl + "transactiondata/onhandsummaryview"

export const TopSalesUrl = BaseUrl + "transactiondata/topsales"
export const ProductInvUrl = BaseUrl + "transactiondata/topinventory"

export const SummaryMasterUrl = BaseUrl + "transactiondata/summarymasterview"
export const SummaryTransactionUrl = BaseUrl + "transactiondata/summarytransactionview"


export const saleslineURL = BaseUrl + "transactiondata/salesline"
export const productlineURL = BaseUrl + "transactiondata/productline"
export const locationlineURL = BaseUrl + "transactiondata/locationline"
export const customerlineURL = BaseUrl + "transactiondata/customerline"
export const inventorylineURL = BaseUrl + "transactiondata/inventoryline"
export const categorylineURL = BaseUrl + "transactiondata/categoryline"
export const stocktransferlineURL = BaseUrl + "transactiondata/stocktransferline"


export const SankeyChartURL = BaseUrl + "transactiondata/SankeyChartView"
export const TreeChartURL = BaseUrl + "transactiondata/TreeChartView"

export const masterdatauploadURL = BaseUrl + "masterdata/UploadCSV/"





export const businesslineURL = BaseUrl + "procurement/businessline";
export const ProcessCategorizationURL = BaseUrl + "procurement/processcategorization";
export const ProcessSpecificationURL = BaseUrl + "procurement/processspecification";
export const CeCoURL = BaseUrl + "procurement/ceco";
export const ProjectURL = BaseUrl + "procurement/project";
export const BudgetCodeURL = BaseUrl + "procurement/budgetcode";
export const StatusURL = BaseUrl + "procurement/status";
export const DeliveryTermURL = BaseUrl + "procurement/deliveryterm";
export const categorydetailsURL = BaseUrl + "procurement/categorydetailViewSet";
export const RequirementURL = BaseUrl + "procurement/requirement/";
export const RequirementgetViewSetURL = BaseUrl + "procurement/RequirementgetViewSet/";


export const RequirementViewSetapprovalsURL = BaseUrl + "procurement/RequirementViewSetapprovals/";



export const RequirementbURL = BaseUrl + "procurement/requirementbuyer";
export const Requirementduplicate = BaseUrl + "procurement/RequirementViewSetduplicate/duplicate_requirement/";
export const RequirementcURL = BaseUrl + "procurement/requirementcontroller";
export const RequirementmURL = BaseUrl + "procurement/requirementmanager";
export const RequirementcoURL = BaseUrl + "procurement/RequirementViewCECOOwner";
export const requirementfilesURL = BaseUrl + "procurement/requirementfilesViewSet";
export const requirementsdetailurl = BaseUrl + "procurement/requirementdetailviewset/";



//Interconnection

export const pgpprojecturl = BaseUrl + "interconnection/Projects/";
export const pgpprojectfileurl = BaseUrl + "interconnection/projectfile/";
export const pgpprojectfileuploadurl = BaseUrl + "interconnection/ProjectfileuploadViewSet/";
