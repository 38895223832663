import React, { FC, useState, useEffect, useRef } from "react";
import { Drawer, notification, Form, Input, Button, DatePicker, Select, Upload } from "antd";
import { AuthTokenType, DataProps, AddRequirementFormProps, ProcessCategorization, ProcessSpecification, CeCo, Projectp, BudgetCode, Status, DeliveryTerm, currency, UserProps, CategoryDetailsprops, BusinessLineprops } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs"; // Import dayjs
import { RequirementURL } from "../utils/network";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { useGetBudgetCodes, useGetUsers, useGetCeCos, useGetCurrency, useGetDeliveryTerms, useGetProcessCategorizations, useGetProcessSpecifications, useGetProjectp, useGetStatuses, useGetbuyers, useGetrequesters, useGetCategorydetails } from "../utils/hooks";
const { Option } = Select;

const AddRequirements: FC<AddRequirementFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingRequirement,
  onCloseWithoutEditing, // new prop
}) => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [fetching, setFetching] = useState<boolean>(true);

  const [requirementType, setRequirementType] = useState<string | null>(null);
  const [subService, setSubService] = useState<string | null>(null);
  const [subSupplies, setSubSupplies] = useState<string | null>(null);
  const [products, setProducts] = useState<Array<{ product: string; description: string; quantity: number; unit: string; requirement_date: dayjs.Dayjs | null }>>([]);

  const [processcategorizations, setprocesscategorizations] = useState<ProcessCategorization[]>([]);
  useGetProcessCategorizations(setprocesscategorizations, setFetching);

  const [processspecification, setprocessspecification] = useState<ProcessSpecification[]>([]);
  useGetProcessSpecifications(setprocessspecification, setFetching);

  const [ceco, setceco] = useState<CeCo[]>([]);
  useGetCeCos(setceco, setFetching);

  const [buyer, setbuyer] = useState<UserProps[]>([]);
  useGetbuyers(setbuyer, setFetching);

  const [requester, setrequester] = useState<UserProps[]>([]);
  useGetUsers(setrequester, setFetching);

  const [project, setproject] = useState<Projectp[]>([]);
  useGetProjectp(setproject, setFetching);

  const [budgetcode, setbudgetcode] = useState<BudgetCode[]>([]);
  useGetBudgetCodes(setbudgetcode, setFetching);

  const [status, setstatus] = useState<Status[]>([]);
  useGetStatuses(setstatus, setFetching);

  const [deliveryterms, setdeliveryterms] = useState<DeliveryTerm[]>([]);
  useGetDeliveryTerms(setdeliveryterms, setFetching);

  const [currrency, setcurrency] = useState<currency[]>([]);
  useGetCurrency(setcurrency, setFetching);

  // New state variables for cascading dropdowns
  const [categorydetails, setCategorydetails] = useState<CategoryDetailsprops[]>([]);
  const [selectedBusinessLine, setSelectedBusinessLine] = useState<number| null>();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(null);
  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<string[]>([]);
  const [autoBudgetCode, setAutoBudgetCode] = useState<BudgetCode | null>(null);
  const [autoceco, setAutoceco] = useState<CeCo | null>(null);

  const [filteredBusinessLines, setFilteredBusinessLines] = useState<BusinessLineprops[]>([]);

  const fileInputsRef = useRef<{ [key: string]: FileList | null }>({});

  useGetCategorydetails(setCategorydetails, setFetching);


  useEffect(() => {
    if (editingRequirement) {
      form.setFieldsValue({
        ...editingRequirement,
        process_categorization_id: editingRequirement.process_categorization?.id,
        process_specification_id: editingRequirement.process_specification?.id,
        ceco_id: editingRequirement.ceco?.id,
        buyer_id: editingRequirement.buyer?.id,
        businessline_id: editingRequirement.businessline?.id,
        requester_id: editingRequirement.requester?.id,
        project_id: editingRequirement.project?.id,
        budget_code_id: editingRequirement.budget_code?.id,
        status_id: editingRequirement.status?.id,
        currency_id: editingRequirement.currency?.id,
        delivery_term_id: editingRequirement.delivery_term?.id,
        original_date: editingRequirement.original_date ? dayjs(editingRequirement.original_date) : null,
      });
    } else {
      form.resetFields();
    }
  }, [editingRequirement, form]);

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
    // Reset cascading dropdowns
    setSelectedBusinessLine(null);
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setAttachments([]);
    setAutoBudgetCode(null);
  };


  const handleFileChange = (key: string, files: FileList | null) => {
    if (files) {
      fileInputsRef.current[key] = files;
    }
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;
  
    // Create a FormData object to include files and other form data
    const formData = new FormData();
  
    // Helper function to format date fields
    const formatDate = (date: any) => {
      if (date) {
        const parsedDate = new Date(date);
        if (!isNaN(parsedDate.getTime())) {
          return parsedDate.toISOString(); // Convert to ISO string format
        }
      }
      return null;
    };
  
    // Append form fields to FormData
    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        // Handle date fields
        if (key.toLowerCase().includes('date')) {
          const formattedDate = formatDate(value);
          if (formattedDate) {
            formData.append(key, formattedDate);
          }
        } else if (key.startsWith('file_')) {
          // Skip file fields here; they are handled separately
          return;
        } else {
          // Convert other values to string and append
          formData.append(key, value.toString());
        }
      }
    });
    Object.keys(fileInputsRef.current).forEach(key => {
      const fileList = fileInputsRef.current[key];
      if (fileList) {
        Array.from(fileList).forEach(file => {
          formData.append(key, file);
        });
      }
    });
    formData.append('budget_code_id', autoBudgetCode ? String(autoBudgetCode.id) : '');;
    // Handle the rest of the form submission
    try {
      const response: AxiosResponse = await axios.post(RequirementURL, formData, headers);
      
      notification.success({
        message: t("Operation Success"),
        description: t("Requirement Created Successfully"),
      });
  
      onSuccessCallBack?.();
      onClose?.(); // Close the drawer
    } catch (error) {
      notification.error({
        message: t("Operation Error"), // Translate the error message
      });
    } finally {
      setLoading(false);
      form.resetFields() 
      fileInputsRef.current = {}
      setRequirementType(null);
      setSubSupplies(null);
      setSelectedBusinessLine(null);
      setSelectedCategory(null);
      setSelectedSubcategory(null);
      setAttachments([]);
      setAutoBudgetCode(null);
      setProducts([]);
      setFilteredBusinessLines([]);
      setFilteredCategories([]);
      setFilteredSubcategories([]);
      
    form.setFieldsValue({
      original_date: dayjs() // Set today's date as the default value
    });    
    }
  };


  
  const renderSubSuppliesOptions = () => {
    const subSuppliesOptions = [
      "General PV Plant",
      "General HV MV",
      "General Office",
    ];
    return subSuppliesOptions.map((option, index) => (
      <Option key={index} value={option}>
        {option}
      </Option>
    ));
  };

  const renderSuppliesFileUploaders = () => {
    if (subSupplies === "General Office") {
      return (
        <>
          <Form.Item label={t("General Description")} name="general_description">
          <input
          type="file"
          multiple
          onChange={(e) => handleFileChange("general_description", e.target.files)}
      
        />
          
          </Form.Item>
        </>
      );
    }
    if (subSupplies === "General PV Plant") {
      return (
        <>
          <Form.Item label={t("General Description")} name="general_description">
          <input
          type="file"
          multiple
          onChange={(e) => handleFileChange("general_description", e.target.files)}
      
        />
          
          </Form.Item>
        </>
      );
    }
    if (subSupplies === "General HV MV") {
      return (
        <>
          <Form.Item label={t("General Description")} name="general_description">
          <input
          type="file"
          multiple
          onChange={(e) => handleFileChange("general_description", e.target.files)}
      
        />

          </Form.Item>
          <Form.Item label={t("ITX: Warranted Technical Data Sheets for Equipment")} name="itx_warranted_technical_data_sheets_for_equipment">
          <input
          type="file"
          multiple
          onChange={(e) => handleFileChange("ITX: Warranted Technical Data Sheets for Equipment", e.target.files)}
      
        />

          </Form.Item>
        </>
      );
    }
    return null;
  };

  const addProductRow = () => {
    setProducts([...products, { product: "",description: "", quantity: 0, unit: "", requirement_date: null }]);
  };

  const removeProductRow = (index: number) => {
    const newProducts = products.filter((_, i) => i !== index);
    setProducts(newProducts);
  };

  const handleProductChange = (index: number, field: string, value: any) => {
    const newProducts = [...products];
    newProducts[index] = { ...newProducts[index], [field]: value };
    setProducts(newProducts);
  };

  const renderProductRows = () => {
    if (requirementType === "Supplies" || requirementType === "Services"  ) {
      return (
        <>
          <Button onClick={addProductRow} style={{ marginBottom: "16px" }}>
            {t("Add Item")}
          </Button>
          {products.map((product, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
              <Form.Item name={`requirement_item_data[${index}].product`} label={t("Product")} style={{ flex: 1 }}>
                <Input value={product.product} onChange={(e) => handleProductChange(index, "product", e.target.value)} />
              </Form.Item>
              <Form.Item name={`requirement_item_data[${index}].description`} label={t("Description")} style={{ flex: 1 }}>
                <Input value={product.description} onChange={(e) => handleProductChange(index, "description", e.target.value)} />
              </Form.Item>
              <Form.Item name={`requirement_item_data[${index}].quantity`} label={t("Quantity")} style={{ flex: 1 }}>
                <Input type="number" value={product.quantity} onChange={(e) => handleProductChange(index, "quantity", e.target.value)} />
              </Form.Item>
              <Form.Item name={`requirement_item_data[${index}].unit`} label={t("Unit")} style={{ flex: 1 }}>
                <Input value={product.unit} onChange={(e) => handleProductChange(index, "unit", e.target.value)} />
              </Form.Item>
              <Form.Item name={`requirement_item_data[${index}].requirement_date`} label={t("Date")} style={{ flex: 1 }}>
                <DatePicker value={product.requirement_date} onChange={(date) => handleProductChange(index, "requirement_date", date)} />
              </Form.Item>
              <Button type="link" onClick={() => removeProductRow(index)} style={{ marginLeft: "8px" }}>
                {t("Remove")}
              </Button>
            </div>
          ))}
        </>
      );
    }
    return null;
  };
  const handleRequirementTypeChange = (value: string) => {
    setRequirementType(value);
    setSubSupplies(null);
    setProducts([]);
    form.resetFields([
      'sub_supplies',
      'product', 
      'quantity', 
      'unit', 
      'requirement_date'
    ]); // Reset fields related to previous requirement type
  };

  useEffect(() => {
    form.setFieldsValue({
      original_date: dayjs()
    });
  }, [form]);

  const [filteredCECO, setFilteredCECO] = useState<CeCo[]>([]);

  useEffect(() => {
    
      // Filter business lines based on categorydetails data
      const businessLines = Array.from(
        new Map(categorydetails.map(detail => [detail.businessline.name, detail.businessline])).values()
      );
      setFilteredBusinessLines(businessLines); // Set filtered unique business line objects
      if (selectedBusinessLine) {
        const filteredCECOs = ceco.filter(detail => detail.businessline?.id === selectedBusinessLine);
        setFilteredCECO(filteredCECOs); // Store the filtered CECOs in state for the dropdown
    
        // Automatically select the first CECO, or reset the field if none is available
        if (filteredCECOs.length > 0) {
          setAutoceco(filteredCECOs[0]);
          form.setFieldsValue({ ceco_id: filteredCECOs[0].id });
        } else {
          setAutoceco(null);
          form.resetFields(['ceco_id']);
        }
  

        const filteredCategoryDetails = categorydetails.filter(detail => detail.businessline?.id === selectedBusinessLine);
        const categories = Array.from(new Set(filteredCategoryDetails.map(detail => detail.category)));
        setFilteredCategories(categories);

        if (selectedCategory) {
          // Filter subcategories based on the selected category
          const subcategories = Array.from(new Set(filteredCategoryDetails.filter(detail => detail.category === selectedCategory).map(detail => detail.subcategory)));
          setFilteredSubcategories(subcategories);

          if (selectedSubcategory) {
            // Fetch attachments and budget code based on the selected subcategory
            const selectedDetail = filteredCategoryDetails.find(detail => detail.category === selectedCategory && detail.subcategory === selectedSubcategory);
            if (selectedDetail) {
              setAttachments(selectedDetail.attachments.split(','));
              setAutoBudgetCode(selectedDetail.budget_code || null);
            }
          }
        }
      }
    
  }, [selectedBusinessLine, selectedCategory, selectedSubcategory, requirementType, categorydetails]);

  return (
    <Drawer
      title={editingRequirement ? t("Edit Requirement") : t("Add Requirement")}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={1300}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
                <Form.Item label={t("Type")} name="r_type" rules={[{ required: true }]}>
          <Select >
            <Option value="Quotation">{t("Quotation")}</Option>
            <Option value="Direct Award">{t("Direct Award")}</Option>
          </Select>
        </Form.Item>

 <Form.Item label={t("Requirement Type")} name="requirement_type" rules={[{ required: true }]}>
          <Select onChange={(handleRequirementTypeChange)}>
            <Option value="Services">{t("Services")}</Option>
            <Option value="Supplies">{t("Supplies")}</Option>
          </Select>
        </Form.Item>

        {(
          <>
            <Form.Item name="businessline_id" label={t("Business Line")} rules={[{ required: true }]}>
              <Select
                  showSearch
                  filterOption={(input, option) => {
                    if (!option) return false;
                    const optionText = option.children as unknown as string;
                    return optionText.toLowerCase().includes(input.toLowerCase());
                  }}
               onChange={(value) => setSelectedBusinessLine(value)}>
 {filteredBusinessLines.map(line => (
                  <Option key={line.id} value={line.id}>
                    {line.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="category" label={t("Category")} rules={[{ required: true }]}>
              <Select
                  showSearch
                  filterOption={(input, option) => {
                    if (!option) return false;
                    const optionText = option.children as unknown as string;
                    return optionText.toLowerCase().includes(input.toLowerCase());
                  }}
                   onChange={(value) => setSelectedCategory(value)}>
                {filteredCategories.map((category) => (
                  <Option key={category} value={category}>{category}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="subcategory" label={t("Subcategory")} rules={[{ required: true }]}>
              <Select     showSearch
                filterOption={(input, option) => {
                  if (!option) return false;
                  const optionText = option.children as unknown as string;
                  return optionText.toLowerCase().includes(input.toLowerCase());
                }}
               onChange={(value) => setSelectedSubcategory(value)}>
                {filteredSubcategories.map((subcategory) => (
                  <Option key={subcategory} value={subcategory}>{subcategory}</Option>
                ))}
              </Select>
            </Form.Item>
            {attachments.map((attachment, index) => (

<Form.Item
          key={index}
          label={attachment}
        >
          <input
            type="file"
            multiple
            onChange={(e) => handleFileChange(attachment, e.target.files)}
          />
        </Form.Item>
      ))}

<Form.Item name="budget_code_id" label={t("Budget Code")}>
  <Input
    value={autoBudgetCode ? autoBudgetCode.id : ''}
    disabled
    placeholder={autoBudgetCode ? autoBudgetCode.code : 'No budget code selected'}
  />
</Form.Item>

          </>
        )}
        <Form.Item
          label={t("Item Description")}
          name="item_description"
          rules={[{ required: true, message: t('Please input the Item Description!') }]}
        >
          <Input placeholder="item_description" />
        </Form.Item>

        {requirementType === "Supplies" && (
          <Form.Item label={t("Sub Supplies")} name="sub_supplies" rules={[{ required: true }]}>
            <Select onChange={(value) => setSubSupplies(value)}>
              {renderSubSuppliesOptions()}
            </Select>
          </Form.Item>
        )}

        {renderSuppliesFileUploaders()}
        {renderProductRows()}


        <Form.Item
          label={t("Process Categorization")}
          name="process_categorization_id"
          rules={[{ required: true, message: t('Please input the Process Categorization!') }]}
        >
          <Select placeholder={t("Process Categorization")}
              showSearch
              filterOption={(input, option) => {
                if (!option) return false;
                const optionText = option.children as unknown as string;
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}>
            {processcategorizations.map((item, index) => (
              <Option value={item.id} key={index}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>


        <Form.Item
  label={t("CECO")}
  name="ceco_id"
  rules={[{ required: true, message: t('Please select the CECO!') }]}
>
  <Select
      showSearch
      filterOption={(input, option) => {
        if (!option) return false;
        const optionText = option.children as unknown as string;
        return optionText.toLowerCase().includes(input.toLowerCase());
      }}
    placeholder={t("Select CECO")}
    value={autoceco ? autoceco.id : undefined}
  onChange={(value) => setAutoceco(filteredCECO.find(item => item.id === value) || null)}
    >
    {filteredCECO.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ))}
  </Select>
</Form.Item>


        <Form.Item
          label={t("Project")}
          name="project_id"
          rules={[{ required: true, message: t('Please input the Project!') }]}
        >
          <Select
              showSearch
              filterOption={(input, option) => {
                if (!option) return false;
                const optionText = option.children as unknown as string;
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              placeholder={t("Project")} disabled={!!editingRequirement}>
            {project.map((item, index) => <Option value={item.id} key={index}>{item.code_name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("PO Number")}
          name="po_number"
          rules={[{ required: false, message: t('Please input the PO Number!') }]}
        >
          <Input placeholder={t("PO Number")} />
        </Form.Item>



        <Form.Item
          label={t("Currency")}
          name="currency_id"
          rules={[{ required: true, message: t('Please input the Currency!') }]}
        >
          <Select placeholder={t("Currency")}>
            {currrency.map((item, index) => <Option value={item.id} key={index}>{item.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("Delivery Term")}
          name="delivery_term_id"
          rules={[{ required: true, message: t('Please input the Delivery Term!') }]}
        >
          <Select
              showSearch
              filterOption={(input, option) => {
                if (!option) return false;
                const optionText = option.children as unknown as string;
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
           placeholder={t("Delivery Term")}>
            {deliveryterms.map((item, index) => <Option value={item.id} key={index}>{item.term}</Option>)}
          </Select>
        </Form.Item>


        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {editingRequirement ? t("Update") : t("Submit")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddRequirements;