import React, { useState } from 'react';
import { getAuthToken } from '../utils/functions';
import { AuthTokenType } from '../utils/types';
import axios from 'axios';
import { pgpprojectfileuploadurl } from '../utils/network';
import { notification, Modal, Button, Upload, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ProjectFileViewer from './ProjectFileViewer';
import ProjectFileTable from './ProjectFileTable';

const { Title, Text } = Typography;

interface FileData {
  id: number;
  filename: string;
  status: 'Pending' | 'Uploaded' | 'Incorrect' | 'Okay';
}

const PMG2 = ({ selectedProject, fetchUpdatedProject }: { selectedProject: any, fetchUpdatedProject: () => void }) => {
  const [file, setFile] = useState<File | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState('');
  const [currentBox, setCurrentBox] = useState('');
  const [fileId, setFileId] = useState<number | null>(null);
  const [showUpload, setShowUpload] = useState(false);
  // Open modal and set file ID based on filename





  
  const openUploadSection = (section: string, box: string) => {
    setCurrentSection(section);
    setCurrentBox(box);
    setShowUpload(true);
    // Find file ID from the existing list
    const fileEntry = selectedProject?.files?.find((file: FileData) => file.filename === box);
    if (fileEntry) {
      setFileId(fileEntry.id);
    } else {
      setFileId(null);
    }

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setFile(null);
    setFileId(null);
    setIsModalOpen(false);
  };

  const handleFileChange = (info: any) => {
    setFile(info.fileList[0]?.originFileObj || null);
  };

  const handleFileUpload = async () => {
    if (!file) {
      notification.error({ message: 'Please select a file before uploading.' });
      return;
    }

    if (!fileId) {
      notification.error({ message: 'File entry not found in backend. Please check again.' });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append("project_id", selectedProject.id);
    formData.append('status', 'Uploaded'); // Updating status to "Uploaded"

    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.patch(`${pgpprojectfileuploadurl}${fileId}/`, formData, headers);

      if (response.status === 200) {
        await fetchUpdatedProject();
        
        notification.success({ message: `File updated successfully for ${currentSection} - ${currentBox}` });
        closeModal();
      } else {
        throw new Error('Unexpected response');
      }
    } catch (error) {
      notification.error({ message: `Failed to update file for ${currentSection} - ${currentBox}` });
    }
  };

  // Function to get file status and apply color
  const getFileStatus = (box: string) => {
    const fileData = selectedProject?.files?.find((file: FileData) => file.filename === box);
    if (!fileData) return { backgroundColor: 'white' };

    switch (fileData.status) {
      case 'Pending': return { backgroundColor: 'goldenrod', color: 'black' };
      case 'Uploaded': return { backgroundColor: 'royalblue', color: 'white' };
      case 'Incorrect': return { backgroundColor: 'crimson', color: 'white' };
      case 'Okay': return { backgroundColor: 'forestgreen', color: 'white' };
      default: return { backgroundColor: 'lightgray', color: 'black' };
    }
  };

  const renderSection = (section: string, rows: string[][]) => {
    return rows.map((row, rowIndex) => (
      <div key={rowIndex} style={{ display: 'flex', gap: '10px', flexDirection: 'row', width: '100%' }}>
        {row.map((box, boxIndex) => (
          <div
            key={boxIndex}
            style={{
              padding: '8px',
              border: '1px solid #ccc',
              margin: '5px',
              cursor: 'pointer',
              textAlign: 'center',
              flex: row.length === 1 ? '1' : `1 1 ${100 / row.length}%`,
              boxSizing: 'border-box',
              ...getFileStatus(box),
            }}
            onClick={() => openUploadSection(section, box)}          >
            {box}
          </div>
        ))}
      </div>
    ));
  };

  const section1Rows = [
    ['CEM Definitiva'], ['ANIT y PLANOS'], ['DUF','ANIT','RI'],['Cronograma']
];

const section2Rows = [
  ['INFORMACIÓN TÉCNICA']
];  

  const section2aRows = [
    ['NFOTÉCNICA PARA ESTUDIOS'],['IT E']
  ];

  const section2bRows = [
  ['IT NO DISPONIBLE'],['PO no disponibles','IT no disponible']
  ];

  const section2cRows = [
    ['ESTUDIOS DE INTERCONEXIÓN'],['ECAP','ET','ECB','ECC','ECA','ESM','EMT'],

  ];

  const section2dRows = [
    ['SCADA Y MEDIDAS']
  ];

  const section2daRows = [
    ['SITR'],['Enlace','DIR/TAG','Fecha Pruebas','Pruebas']
  ];

  const section2dbRows = [
    ['EME'],['Enlace','Fecha Pruebas','Pruebas'],
   ];


  const section2eRows = [
    ['OTROS PES'],['GM','Prog P','Prot E','Prot P','PO N','Carta SEC','Plan Ener Gx'],
  ];

  const section2fRows = [
  ['PLATAFORMAS PES'],['Opreal','Pronósticos','Neomante']
  ];

  const section3aRows = [
    ['PLATAFORMAS EO'], ['REUC','P. Pagos']];

    const section3bRows = [
      ['OTROS EO'], ['IT PES','CVNC','VMD','Pruebas End to End','PO E']];

      const section3cRows = [
        ['PARÁMETROS DE GENERACIÓN'], ['PMax','PPyD','MT','CEN','CEN diseño']];

        const section3dRows = [
          ['SEO'], ['REPORTES DEL PROCESO'], ['Reporte IT Estudios','Reporte PES','CEN','Reporte EO']];

          const section3eRows = [
          ['REPORTES DEL PROCESO'], ['Reporte IT Estudios','Reporte PES','CEN','Reporte EO']];
  

  return (
    <div>
    <div className='card' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div className="cardHeader">
        <h2 className="headContent">PMG</h2>
      </div>

      <div style={{ display: 'flex', gap: '20px', flexDirection: 'row', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
          <h3>CEM Y OTROS</h3>
          {renderSection('Section 1', section1Rows)}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', flex: 1 }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
        <h3>REQUISITOS PES</h3>

  {/* Section 2 - Positioned at the Top */}
  {renderSection('Section 2', section2Rows)}

  {/* Wrapper div to place Section 2a and Section 2b side by side */}
  <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', width: '100%' }}>
    
    {/* Section 2a */}
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {renderSection('Section 2a', section2aRows)}
    </div>

    {/* Section 2b */}
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {renderSection('Section 2b', section2bRows)}
    </div>
  </div>
  <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {renderSection('Section 2c', section2cRows)}
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
  
  {/* Section 2d (Top) */}
  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    {renderSection('Section 2d', section2dRows)}
  </div>

  {/* Section 2da and 2db (Side by Side) */}
  <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', width: '100%' }}>
    
    {/* Section 2da */}
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {renderSection('Section 2da', section2daRows)}
    </div>

    {/* Section 2db */}
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {renderSection('Section 2db', section2dbRows)}
    </div>

  </div>

</div>

<div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {renderSection('Section 2e', section2eRows)}
    </div>

    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {renderSection('Section 2f', section2fRows)}
    </div>

</div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: '20px' }}>

{/* Section 3a (Top) */}
<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>

<h3>REQUISITOS EO</h3>
  {renderSection('Section 3a', section3aRows)}
</div>
</div>

{/* Section 3b */}
<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
  {renderSection('Section 3b', section3bRows)}
</div>

{/* Section 3c */}
<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
  {renderSection('Section 3c', section3cRows)}
</div>

{/* Section 3d */}
<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
  {renderSection('Section 3d', section3dRows)}
</div>

{/* Section 3d */}
<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
  {renderSection('Section 3e', section3eRows)}
</div>

</div>

        </div>


      </div>
      
      <div>
      {showUpload && (
 <div className='card' style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
 <h3>Upload File for {currentSection} - {currentBox}</h3>
 <Upload beforeUpload={() => false} onChange={handleFileChange} maxCount={1}>
   <Button icon={<UploadOutlined />}>Select File</Button>
 </Upload>
 <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
   <Button type="primary" onClick={handleFileUpload}>Upload</Button>
 </div>
 </div>
      )}
      <div className="card"  style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Title level={3} className="card-title">Project Details</Title>
        <div className="card-grid">
          <Text><strong>ID:</strong> {selectedProject.id}</Text>
          <Text><strong>Name:</strong> {selectedProject.name}</Text>
          <Text><strong>Code:</strong> {selectedProject.code}</Text>
          <Text><strong>Type:</strong> {selectedProject.project_type}</Text>
          <Text><strong>Technology:</strong> {selectedProject.technology}</Text>
          <Text><strong>PES Date:</strong> {selectedProject.pes_date}</Text>
          <Text><strong>Substation:</strong> {selectedProject.substation}</Text>
          <Text><strong>Feeder:</strong> {selectedProject.feeder}</Text>
          <Text><strong>Distribution Company:</strong> {selectedProject.distribution_company}</Text>
          <Text><strong>Power (MW):</strong> {selectedProject.power_mw}</Text>
          <Text><strong>Num POC:</strong> {selectedProject.num_poc}</Text>
          <Text><strong>SPV:</strong> {selectedProject.spv}</Text>
        </div>
      </div>

      <div className="card"  style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <ProjectFileTable selectedProject={selectedProject} />
      </div>

    
    </div>
    </div>
  );
};

export default PMG2;
