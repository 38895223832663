import React, { FC, useState } from "react";
import { Button, Table, Modal, notification, Input, Select, DatePicker, Drawer, Tabs } from "antd";
import { AuthTokenType } from "../utils/types";
import { getAuthToken, getpgpproject } from "../utils/functions";
import axios from "axios";
import { useGetpgpproject } from "../utils/hooks";
import { pgpprojectfileuploadurl, pgpprojecturl } from "../utils/network";
import ContentLayoutnew from "./ContentLayoutnew";
import dayjs from "dayjs"; // Import dayjs
import PMGD from "./PMGD";
import PMG from "./PMG";
import ProjectFileViewer from "./ProjectFileViewer";
import PMG2 from "./PMG2";
import TabPane from "antd/es/tabs/TabPane";

const { Option } = Select;

const PGPProject: FC = () => {

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentProjectType, setCurrentProjectType] = useState<string | null>(null);
  
  const openDrawer = (record: any) => {
    setCurrentProjectType(record.project_type);
    setuploadprojects(record)
    setDrawerVisible(true);
  };
  
  const closeDrawer = () => {
    setDrawerVisible(false);
    setCurrentProjectType(null);
    getpgpproject(setProjects, setFetching);
  };

  const [viewdrawerVisible, setviewDrawerVisible] = useState(false);

  const [uploadprojects, setuploadprojects] = useState<any>();
  const [viewprojects, setviewprojects] = useState<any>();


  const handleView = (record: any) => {
    setviewprojects(record);
    setviewDrawerVisible(true);

  };

  
  const closeviewDrawer = () => {
    setviewDrawerVisible(false);
    getpgpproject(setProjects, setFetching);

  };

  
  const [projects, setProjects] = useState<any[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [newProject, setNewProject] = useState<any>({
    id: -1,
    name: "",
    code: "",
    project_type: "",
    technology: "",
    pes_date: null,
    substation: "",
    feeder: "",
    distribution_company: "",
    power_mw: "",
    num_poc: "",
    spv: "",
  });
  const [editingProject, setEditingProject] = useState<any | null>(null);

  useGetpgpproject(setProjects, setFetching);

  const handleDeleteSelected = async (ids: number[]) => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      await Promise.all(ids.map((id) => axios.delete(`${pgpprojecturl}${id}`, headers)));
      getpgpproject(setProjects, setFetching);
      notification.success({ message: "Selected Projects deleted successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to delete selected Projects!" });
    }
  };

  const fetchUpdatedProject = async () => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.get(`${pgpprojecturl}${uploadprojects.id}/`, headers);
      setuploadprojects(response.data);
    } catch (error) {
      console.error("Failed to fetch updated project:", error);
    }
  };

  const fetchviewProject = async () => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.get(`${pgpprojecturl}${viewprojects.id}/`, headers);
      setviewprojects(response.data);  // Update parent state with fresh data
    } catch (error) {
      console.error("Failed to fetch updated project:", error);
    }
  };

  const handleDelete = async (recordId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this project?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          await axios.delete(`${pgpprojecturl}${recordId}/`, headers);
          getpgpproject(setProjects, setFetching);
          notification.success({ message: "Project deleted successfully!" });
        } catch (error) {
          notification.error({ message: "Failed to delete project!" });
        }
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleAddRow = () => {
    setIsAdding(true);
    setNewProject({
      id: -1,
      name: "",
      code: "",
      project_type: "",
      technology: "",
      pes_date: null,
      substation: "",
      feeder: "",
      distribution_company: "",
      power_mw: "",
      num_poc: "",
      spv: "",
    });
  };

  const handleSaveNewProject = async () => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(pgpprojecturl, newProject, headers);
      setProjects((prev) => [...prev, response.data]);
      setNewProject({ id: -1, name: "", code: "" });
      setIsAdding(false);
      notification.success({ message: "Project added successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to add project!" });
    }
  };

  const handleEdit = (record: any) => {
    setEditingProject(record);
  };

  const handleSaveEdit = async (project: any) => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      await axios.put(`${pgpprojecturl}${project.id}/`, project, headers);
      getpgpproject(setProjects, setFetching);
      setEditingProject(null);
      notification.success({ message: "Project updated successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to update project!" });
    }
  };

  const handleCancelEdit = () => {
    setEditingProject(null);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newProject.name}
              onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
              style={{ width: "100%" }}
            />
          );
        }
        if (editingProject && editingProject.id === record.id) {
          return (
            <Input
              value={editingProject.name}
              onChange={(e) => setEditingProject({ ...editingProject, name: e.target.value })}
            />
          );
        }
        return text;
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newProject.code}
              onChange={(e) => setNewProject({ ...newProject, code: e.target.value })}
              style={{ width: "100%" }}
            />
          );
        }
        if (editingProject && editingProject.id === record.id) {
          return (
            <Input
              value={editingProject.code}
              onChange={(e) => setEditingProject({ ...editingProject, code: e.target.value })}
            />
          );
        }
        return text;
      },
    },
    {
      title: "Project Type",
      dataIndex: "project_type",
      key: "project_type",
      render: (text: string, record: any) => {
        const projectTypes = ["PMGD", "PMG", "Utility"];
    
        if (isAdding && record.id === -1) {
          return (
            <Select
              value={newProject.project_type}
              onChange={(value) =>
                setNewProject({ ...newProject, project_type: value })
              }
              style={{ width: "100%" }}
            >
              {projectTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          );
        }
    
        if (editingProject && editingProject.id === record.id) {
          return (
            <Select
              value={editingProject.project_type}
              onChange={(value) =>
                setEditingProject({ ...editingProject, project_type: value })
              }
              style={{ width: "100%" }}
            >
              {projectTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          );
        }
    
        return text;
      },
    },
    {
      title: "Technology",
      dataIndex: "technology",
      key: "technology",
      render: (text: string, record: any) => {
        const technologyChoices = ["Hybrid", "Stand Alone", "Solar"];
    
        if (isAdding && record.id === -1) {
          return (
            <Select
              value={newProject.technology}
              onChange={(value) =>
                setNewProject({ ...newProject, technology: value })
              }
              style={{ width: "100%" }}
            >
              {technologyChoices.map((tech) => (
                <Select.Option key={tech} value={tech}>
                  {tech}
                </Select.Option>
              ))}
            </Select>
          );
        }
    
        if (editingProject && editingProject.id === record.id) {
          return (
            <Select
              value={editingProject.technology}
              onChange={(value) =>
                setEditingProject({ ...editingProject, technology: value })
              }
              style={{ width: "100%" }}
            >
              {technologyChoices.map((tech) => (
                <Select.Option key={tech} value={tech}>
                  {tech}
                </Select.Option>
              ))}
            </Select>
          );
        }
    
        return text;
      },
    },
    {
      title: "Substation",
      dataIndex: "substation",
      key: "substation",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newProject.substation}
              onChange={(e) =>
                setNewProject({ ...newProject, substation: e.target.value })
              }
              style={{ width: "100%" }}
            />
          );
        }
        if (editingProject && editingProject.id === record.id) {
          return (
            <Input
              value={editingProject.substation}
              onChange={(e) =>
                setEditingProject({ ...editingProject, substation: e.target.value })
              }
            />
          );
        }
        return text;
      },
    },
    {
      title: "Feeder",
      dataIndex: "feeder",
      key: "feeder",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newProject.feeder}
              onChange={(e) => setNewProject({ ...newProject, feeder: e.target.value })}
              style={{ width: "100%" }}
            />
          );
        }
        if (editingProject && editingProject.id === record.id) {
          return (
            <Input
              value={editingProject.feeder}
              onChange={(e) => setEditingProject({ ...editingProject, feeder: e.target.value })}
            />
          );
        }
        return text;
      },
    },
    {
      title: "Power [MW]",
      dataIndex: "power_mw",
      key: "power_mw",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newProject.power_mw}
              onChange={(e) =>
                setNewProject({ ...newProject, power_mw: e.target.value })
              }
              type="number"
              style={{ width: "100%" }}
            />
          );
        }
        if (editingProject && editingProject.id === record.id) {
          return (
            <Input
              value={editingProject.power_mw}
              onChange={(e) =>
                setEditingProject({ ...editingProject, power_mw: e.target.value })
              }
              type="number"
            />
          );
        }
        return text;
      },
    },
    {
      title: "POC",
      dataIndex: "num_poc",
      key: "num_poc",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newProject.num_poc}
              onChange={(e) =>
                setNewProject({ ...newProject, num_poc: e.target.value })
              }
              type="number"
              style={{ width: "100%" }}
            />
          );
        }
        if (editingProject && editingProject.id === record.id) {
          return (
            <Input
              value={editingProject.num_poc}
              onChange={(e) =>
                setEditingProject({ ...editingProject, num_poc: e.target.value })
              }
              type="number"
            />
          );
        }
        return text;
      },
    },
    {
      title: "SPV",
      dataIndex: "spv",
      key: "spv",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newProject.spv}
              onChange={(e) => setNewProject({ ...newProject, spv: e.target.value })}
              style={{ width: "100%" }}
            />
          );
        }
        if (editingProject && editingProject.id === record.id) {
          return (
            <Input
              value={editingProject.spv}
              onChange={(e) => setEditingProject({ ...editingProject, spv: e.target.value })}
            />
          );
        }
        return text;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <span>
              <Button type="link" onClick={handleSaveNewProject}>
                Save
              </Button>
              <Button
                type="link"
                danger
                onClick={() => {
                  setIsAdding(false);
                  setNewProject({
                    id: -1,
                    name: "",
                    code: "",
                    project_type: "",
                    technology: "",
                    pes_date: null,
                    substation: "",
                    feeder: "",
                    distribution_company: "",
                    power_mw: "",
                    num_poc: "",
                    spv: "",
                  });
                }}
              >
                Cancel
              </Button>
            </span>
          );
        }
        if (editingProject && editingProject.id === record.id) {
          return (
            <span>
              <Button type="link" onClick={() => handleSaveEdit(editingProject)}>
                Save
              </Button>
              <Button type="link" danger onClick={() => setEditingProject(null)}>
                Cancel
              </Button>
            </span>
          );
        }
        return (
          <span>
                          <Button type="link" onClick={() => openDrawer(record)}>
            Upload
          </Button>

            <Button type="link" onClick={() => handleEdit(record)}>
              Edit
            </Button>

            <Button type="link" onClick={() => handleView(record)}>
              View
            </Button>

            <Button type="link" danger onClick={() => handleDelete(record.id)}>
              Delete
            </Button>
          </span>
        );
      },
    },
  ];

  const dataSource = isAdding
    ? [
        ...projects,
        {
          id: -1,
          name: newProject.name,
          code: newProject.code,
          project_type: newProject.project_type,
          technology: newProject.technology,
          pes_date: newProject.pes_date,
          substation: newProject.substation,
          feeder: newProject.feeder,
          distribution_company: newProject.distribution_company,
          power_mw: newProject.power_mw,
          num_poc: newProject.num_poc,
          spv: newProject.spv,
        },
      ]
    : projects;

    const DrawerContent = () => {
      if (currentProjectType === "PMGD") {
        return <PMGD selectedProject={uploadprojects} fetchUpdatedProject={fetchUpdatedProject} />;
      }
      if (currentProjectType === "Utility" || currentProjectType === "PMG") {
        return (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Part 1" key="1">
              <PMG selectedProject={uploadprojects} fetchUpdatedProject={fetchUpdatedProject} />
            </TabPane>
            <TabPane tab="Part 2" key="2">
              <PMG2 selectedProject={uploadprojects} fetchUpdatedProject={fetchUpdatedProject} />
            </TabPane>
          </Tabs>
        );
      }
      return null;
    };

  return (
    <>
    <ContentLayoutnew
      pageTitle="PGP Projects"
      buttontitle="Add Project"
      setModalState={handleAddRow}
      dataSource={dataSource as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      onAddRow={handleAddRow}
      onDeleteSelected={handleDeleteSelected}
    />
    <Drawer
      title="Upload"
      visible={drawerVisible}
      onClose={closeDrawer}
      width={1500}
    >
      <DrawerContent />
    </Drawer>
    <ProjectFileViewer
  visible={viewdrawerVisible}  
  onClose={closeviewDrawer}
  selectedProject={viewprojects}
  fetchUpdatedProject={fetchviewProject}
/>

  </>
  );
};

export default PGPProject;
