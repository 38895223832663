import  {AxiosError} from 'axios'

import moment from 'moment';
import dayjs, { Dayjs } from "dayjs";
export interface DataProps{
    [key: string]:string|boolean|number|null|React.ReactElement|((text: string) => React.ReactNode)| undefined;
    
 
}

export interface CustomAxiosError extends Omit<AxiosError,'response'>{
    response? :{
        data:{
            error:string
        } 
    }
}

export interface AuthTokenType{
    headers:{
        Authorization:string}
}

export interface UserType{
    email:string
    fullname:string
    id:number
    created_at:string
    role:string
    last_login:string
    
}

export interface AuthProps{
    errorCallBack?:()=>void,
    successCallBack?:()=>void,
}


export interface StoreProps{
    user:UserType | null,
    updatePasswordUserId:number|null
}

export enum ActionTypes{
    UPDATE_USER_INFO ="[action] update user info",
    UPDATE_PASSWORD_USER_ID ="[action] update password id"
}

export type ActionProps={
    type: ActionTypes.UPDATE_USER_INFO,
    payload:UserType | null
}| {
    type: ActionTypes.UPDATE_PASSWORD_USER_ID,
    payload:number | null
}

export interface StoreProviderProps{
    state:StoreProps,
    dispatch:(arg:ActionProps)=>void
}




export interface UserProps{
    created_at:string
    email:string
    fullname:string
    is_active:string
    last_login:string
    submodule:string
    role:string
    designation:string
    key?:number
    id:number
  
  }
  

 
  export interface AddGroupFormModalProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
}



export interface AddOnHandInventoryFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
}


export interface OnhandInventoryProps{
  key:string;
  id:number
  totalunits:number
  status:string
  internal_reference:string
  po_number:string
  bill_of_lading:string
  supplier:supplier | null
  project:project | null
  sku_code:{
    code:string
    name:string
    subname:string
    
    category:{
      name:string
      parent:{
        name:string
      }
    }
    }
  storage_location:{
      name:string
      }
      created_at:string
  }


export interface GroupProps{
    name:string
    
  }


export interface AddUserFormModalProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingUser?: UserProps | null;
    onCloseWithoutEditing:()=>void

}
 

export interface category {
  id: number;
  name: string;
  parent: category | null; // Allow parent to reference another category or be null for top-level categories
  is_active: boolean;
}

export interface AddCategoryFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingCategory?: category | null;
    onCloseWithoutEditing:()=>void

}

export interface AddUnitofmeasureFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingUnitofmeasure?: unitofmeasure | null;
    onCloseWithoutEditing:()=>void

}


  // Unit of Measure model
export interface unitofmeasure {
    id: number;
    name: string;
    is_active: boolean;
    
  }

export interface AddCurrencyFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingCurrency?: currency | null;
    onCloseWithoutEditing:()=>void
}


  // Currency model
export interface currency {
    id: number;
    name: string;
    is_active: boolean;
  }
  
export interface AddLocationFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingLocation?: location | null;
    onCloseWithoutEditing:()=>void
}


  // Location model
export interface location {
    id: number;
    name: string;
    type: string;
    address: string;
    city: string;
    site_manager: string;
    country: string;
  }

export interface AddProjectFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingProject?: project | null;
    onCloseWithoutEditing:()=>void
}

  // Customer model
  export interface project {
    id: number;
    portfolio: string;
    name: string;
    city: string;
    person_responsible: string;
  }

export interface AddPricebylocationFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingPricebyLocation?: Pricebylocation | null;
  onCloseWithoutEditing:()=>void
}

export interface Pricebylocation
{
          key:string;
          id:number
          product_details:{
            name:string
            id:number
            }
          location_details?:{
              name:string
              id:number  
            }
          sku_price:number
          }








export interface AddSupplierFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingSupplier?: supplier | null;
    onCloseWithoutEditing:()=>void
}

  // Supplier model
export interface supplier {
    id: number;
    name: string;
    email: string;
    address: string;
    city: string;
    sales_person_name:string,
    sales_person_email:string
    country: string;
    phone: string;
    type: string;
  }

  
export interface AddProductFormProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingProduct?: product | null;
    onCloseWithoutEditing:()=>void
}


  // Product model
export interface product {
  field?:string
    id: number;
    quantity?:number;
    image:string;
    imageinfo:string;
    name: string;
    subname: string;
    code: string;
    sku_description: string;
    sku_uom: unitofmeasure;
    sku_buy_cost: number;
    sku_price: number;
    category: category;
    currency: currency;
    
    uom:string,
    curr:string
    cat:string
    subcat:string
  }
  
  export interface AddRemovePurchaseItemProps{
    [key:number]:number
  }

  export interface EditPurchaseOrderProps{
    isVisible?:boolean
    onSuccessCallBack:()=>void
    onClose: () =>void
    editingPurchase?: purchaseProps | null;
    onCloseWithoutEditing:()=>void
}

  export interface purchaseProps
{
          key:string;
          id:number
          item:string
          subname:string
          downpayment?: boolean
          secondpayment?: boolean
          thirdpayment?: boolean
          total_price?:string
          din_paid?: boolean
          bol?: boolean
          po_number?:string
          departure_date?:Date|moment.Moment|string 
          free_days?:string
          material? :string
          origin_country? :string
          origin_port? :string
          destination_port? :string
          container_id? :string
          portfolio? :string
          project? :project
          project1? :string
          shipping_company? :string
          certificate_of_origin?:String
          din_number? :string
          expected_delivery_date?:Date|moment.Moment|string
          actual_delivery_date?:Date|moment.Moment|string
          quantity:number
          status?:string
          amount?:number
          cost:number
          change_delivery_date?:Date
          action?:React.ReactElement

          from_location?:{
            name:string
            }
            to_location?:{
              name:string
              }
          purchaseorder_items?:{
          item:{
                  id:string
                  name:string
                  subname:string
                  quantity:number
                  cost:number
                }
                  }[],
          
          }
                  export interface AddRemovestocktransferItemProps{
                    [key:number]:number
                  }

                  export interface EditTransferOrderProps{
                    isVisible?:boolean
                    onSuccessCallBack:()=>void
                    onClose: () =>void
                    editingTransfers?: stocktransferProps | null;
                    onCloseWithoutEditing:()=>void
                }
                
                  export interface stocktransferProps
                {
                          key:string;
                          id:number;
                          subname:string;
                          name:string
                          supplier_name?:string
                          supplier_id?:string
                          container_id?:string
                          expected_delivery_date?:Date|moment.Moment|string
                          actual_delivery_date?:Date|moment.Moment|string
                          departure_date? :Date|moment.Moment|string
                          project? :project
                          project1? :string
                          portfolio? :string
                          internal_reference? :string
                          transport_company? :string
                          truck_plate? :string
                          guide_number? :string
                          quantity:number
                          status?:string
                          change_delivery_date?:Date
                          action?:React.ReactElement
                          from_location?:{
                            name:string
                            }
                            to_location?:{
                              name:string
                              }
                          stocktransferorder_items?:{
                          item:{
                                  id:string
                                  name:string
                                  subname:string
                                  quantity:number
                                  supplier:supplier|null
                                }
                                  }[],
                          
                          }
        

                          export interface InventoryProps{
                            id:number,
                            name:string,
                            subname?:string,
                            status?:string,
                            material_code?:string,
                            category_name?:string;
                            sub_category_name?:string;
                            code: string,
                            project: string,
                            project_name?: string,
                            location_id: string,
                            location_name: string,
                            supplier_id: string,
                            supplier_name: string,
                            total_units: number
            
                          }
                          


                          export interface AddRemoveestimatesItemProps{
                            [key:number]:number
                          }
                        
                          export interface estimatesProps
                        {
                                  key:string;
                                  id:number
                                  item:string
                                  quantity:number
                                  status?:string
                                  action?:React.ReactElement
                                  from_location?:{
                                    name:string
                                    }
                                    lead?:{
                                      name:string
                                      }
                                  estimate_items?:{
                                  item:{
                                          id:string
                                          name:string
                                          quantity:number
                                        }
                                          }[],
                                  
                                  }
                
                                  export interface linegraphprops{
                                    status?:string
                                    value:number
                                    key?:number
                                    id:number
                                    month?:string
                                  
                                  }


                                  export interface Shipment {
                                    id: number;
                                    reference: string;
                                    ci : string;
                                    supplier : string;
                                    total_container : string;
                                    vessel_name : string;
                                    dispatch_no : string;
                                    din : string;
                                    comments: string;
                                    project: project|null;
                                    type_of_material: category|null;
                                    custom_agency: string;

                                    bill_of_lading: string;
                                    
                                    got_original_bl: boolean;
                                    return_avl:boolean,
                                    container_id: string;
                                    real_etd: Dayjs|string|any; // You can use a specific date format like "YYYY-MM-DD"
                                    real_eta: Date|moment.Moment|string|any; // You can use a specific date format like "YYYY-MM-DD"
                                    real_discharge: Date|moment.Moment|string|any; // You can use a specific date format like "YYYY-MM-DD"
                                    free_days: number;
                                    port_of_discharge: string;
                                    finish_free_days: Date|moment.Moment|string|any; // You can use a specific date format like "YYYY-MM-DD"
                                    start_demurrage: Date|moment.Moment|string|any; // You can use a specific date format like "YYYY-MM-DD"
                                    daily_demurrage_cost: number;
                                    returned_date: Date|moment.Moment|string|any; // You can use a specific date format like "YYYY-MM-DD"
                                    total_demurrage: number;
                                  }
                                  
                                  export interface AddShipmentFormProps {
                                    isVisible?:boolean
                                    onSuccessCallBack:()=>void
                                    onClose: () =>void
                                    editingShipment?: Shipment | null;
                                    onCloseWithoutEditing:()=>void
                                
                                  }

///////////////////////////////Procurement



export interface AddBusinessLineFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingBusinessLine?: BusinessLineprops | null;
  onCloseWithoutEditing:()=>void
}

export interface BusinessLineprops {
  id: number;
  name: string;
  dual_approval:string
}


export interface AddProcessCategorizationFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingProcessCategorization?: ProcessCategorization | null;
  onCloseWithoutEditing:()=>void
}

export interface ProcessCategorization {
  id: number;
  name: string;
}

export interface AddProcessSpecificationFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingProcessSpecification?: ProcessSpecification | null;
  onCloseWithoutEditing:()=>void
}


export interface ProcessSpecification {
  id: number;
  name: string;
}

export interface AddCeCoFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingCeCo?: CeCo | null;
  onCloseWithoutEditing:()=>void
}



export interface CeCo {
  id: number;
  name: string;
  businessline: BusinessLineprops;
  ceco_owner:UserProps|null;
}



export interface Requester {
  id: number;
  name: UserProps;
}


export interface AddProjectpFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingProjectps?: Projectp | null;
  onCloseWithoutEditing:()=>void
}



export interface Projectp {
  id: number;
  code_name: string;
  client: string;
  project_manager: UserProps|null;
  mw: string;
}

export interface AddBudgetCodeFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingBudgetCodes?: BudgetCode | null;
  onCloseWithoutEditing:()=>void
}


export interface BudgetCode {
  id: number;
  code: string;
}


export interface AddStatusFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingStatus?: Status | null;
  onCloseWithoutEditing:()=>void
}


export interface Status {
  id: number;
  name: string;
}


export interface AddDeliveryTermFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingDeliveryTerms?: DeliveryTerm | null;
  onCloseWithoutEditing:()=>void
}


export interface DeliveryTerm {
  id: number;
  term: string;
}


export interface AddCategoryDetailsFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingCategorydDetails?: CategoryDetailsprops | null;
  onCloseWithoutEditing:()=>void
}


export interface CategoryDetailsprops {
  id: number;
  businessline:BusinessLineprops 
  category :string
  subcategory:string
  attachments:string
  budget_code :BudgetCode
}



export interface AddRequirementFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingRequirement?: Requirement | null;
  onCloseWithoutEditing:()=>void
}


export interface Requirement {
  id: number;
  r_type?:string|null;
  requirement_type?:string|null;
  sub_service?:string|null;
  sub_supplies?:string|null;
  r_id: string|null;
  businessline:BusinessLineprops;
  business_line:BusinessLineprops; 
  category :string;
  subcategory:string;
  controller_comments?:string;
  manager_comments?:string;
  buyer_comments?:string;
  ceco_owner_comments?:string;
  ceco_owner_approval:string;
  original_date: Date|moment.Moment|string|any;
  process_categorization: ProcessCategorization|null;
  process_specification: ProcessSpecification|null;
  ceco: CeCo|null;
  buyer: UserProps|null;
  item_description: string|null;
  project: Projectp|null;
  budget_code: BudgetCode|null;
  requester: UserProps|null;
  status: Status|null;
  po_number: string|null;
  net_value: number|null;
  currency: currency|null;
  delivery_term: DeliveryTerm|null;
  supplier: string|null;
  manager_approval?: string|null;
  controller_approval?: string|null;
  requirement_items?:{
            id:string|null|any
            description:string|null
            product:string|null
            quantity:number|null
            unit:string|null
            selection:string|null|any
            requirement_date:Date|moment.Moment|string|any|null;          
            }[],

}



export interface AddDemmurageFormProps{
  isVisible?:boolean
  onSuccessCallBack:()=>void
  onClose: () =>void
  editingdemmurage?: DemurrageControl | null;
  onCloseWithoutEditing:()=>void
}


export interface DemurrageControl {
  id: number;
  reference: string;
  project: string;
  supplier: string;
  material: string;
  bl_number: string;
  bl_type: string;
  shipping_line: string;
  container_quantity: number;
  bill_of_lading_quote: string;
  demurrage_payment: string;
  container_return: string;
  provision_payment: string;
  din_payment: string;
  custom_release: string;
  stock_site_wh: string;
  shipping_date: Date|moment.Moment|string|any|null;
  delivery_date: Date|moment.Moment|string|any|null;
  free_days: number;
  free_days_expiration: Date|moment.Moment|string|any|null;
  demurrage_start: Date|moment.Moment|string|any|null;
  total_demurrage_usd: number;
  demurrage_payment_status: string;
  demurrage_payment_date: Date|moment.Moment|string|any|null;
  purchase_order_demurrage: string;
  invoice_number: string;
  custom_provision: number;
  din: number;
  comments: string;
}