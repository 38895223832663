import React from "react";
import { Table, Tag } from "antd";

interface File {
  id: number;
  filename: string;
  file_type: string | null;
  status: string;
  file: string | null;
  created_at: string;
  status_logs: { timestamp: string; status: string }[];
}

interface Project {
  id: number;
  name: string;
  files: File[];
}

interface ProjectFileTableProps {
  selectedProject: Project | null;
}

const ProjectFileTable: React.FC<ProjectFileTableProps> = ({ selectedProject }) => {
  const columns = [
    {
      title: "Filename",
      dataIndex: "filename",
      key: "filename",
      render: (text: string, record: File) => (
        <a href={record.file || "#"} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        const statusColors: Record<string, { backgroundColor: string; color: string }> = {
          Pending: { backgroundColor: "goldenrod", color: "black" },
          Uploaded: { backgroundColor: "royalblue", color: "white" },
          Incorrect: { backgroundColor: "crimson", color: "white" },
          Okay: { backgroundColor: "forestgreen", color: "white" },
        };

        const style = statusColors[status] || { backgroundColor: "gray", color: "white" };
        return <Tag style={style}>{status}</Tag>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: string) => new Date(date).toLocaleString(),
    },
    {
      title: "Status Logs",
      dataIndex: "status_logs",
      key: "status_logs",
      render: (logs: { timestamp: string; status: string }[]) =>
        logs.length > 0 ? (
          <ul>
            {logs.map((log, index) => (
              <li key={index}>
                <strong>{log.status}</strong> at {new Date(log.timestamp).toLocaleString()}
              </li>
            ))}
          </ul>
        ) : (
          "No logs"
        ),
    },
  ];

  return <Table columns={columns} dataSource={selectedProject?.files} rowKey="id" />;
};

export default ProjectFileTable;