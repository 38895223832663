import { FC, useEffect, useState } from "react";
import { Button, Table, notification, Input, Upload, Modal } from "antd";
import { AuthTokenType, DataProps } from "../utils/types";
import axios, { AxiosResponse } from "axios";
import { getAuthToken } from "../utils/functions";
import { masterdatauploadURL } from "../utils/network";

import * as XLSX from 'xlsx';

interface ContentLayoutProps {
  pageTitle: string;
  buttontitle: string;
  setModalState: (val: boolean) => void;
  dataSource: DataProps[];
  columns: DataProps[];
  fetching: boolean;
  includeFilters: boolean;
  model_name?: string;
  onAddRow: () => void; // Add onAddRow as a prop to handle row addition
  onDeleteSelected: (ids: number[]) => void,
}

// Define types for headers and rows
interface FilePreview {
  headers: string[];
  rows: any[]; // Each row can be an object or array, depending on your structure
}

const ContentLayoutnew: FC<ContentLayoutProps> = ({
  pageTitle,
  buttontitle,
  setModalState,
  dataSource,
  columns,
  fetching,
  model_name,
  includeFilters,
  onAddRow, // Use the onAddRow handler here
  onDeleteSelected,
}) => {
  const [filteredColumns, setFilteredColumns] = useState(columns);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys: React.Key[]) => {
      setSelectedRowKeys(selectedKeys as number[]);
    },

  };
  console.log(selectedRowKeys)

  const handleDeleteSelected = () => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete the selected items?",
      onOk: () => {
        onDeleteSelected(selectedRowKeys);
        setSelectedRowKeys([]); // Clear selection after deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleUpload = async (file: File) => {
    // Use FileReader to read the file
    const reader = new FileReader();
  
    reader.onload = async (e) => {
      const fileData = e.target?.result as string;
  
      // Parse the Excel file
      const workbook = XLSX.read(fileData, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
      const sheet = workbook.Sheets[sheetName];
  
      // Convert sheet to JSON
      const fileContent = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
      // Extract headers and rows
      const headers = fileContent[0] as string[]; // Explicitly type as string[]
      const rows = fileContent.slice(1) as any[]; // Explicitly type as any[] to handle row data
  
      // Display file content to the user before upload
      setFilePreview({ headers, rows });
  
      // Ask user for confirmation via a modal
      Modal.confirm({
        title: 'Confirm File Upload',
        content: (
          <div>
            <h3>File Contents:</h3>
            <Table
            className="custom-table"
              dataSource={rows.map((row, index) => ({ key: index, ...row }))}
              columns={headers.map((header, index) => ({
                title: header,
                dataIndex: index.toString(),
                key: index.toString(),
              }))}
              pagination={false}
              rowKey="key"
            />
          </div>
        ),
        onOk: () => proceedWithUpload(file), // Proceed with upload if confirmed
        onCancel: () => {
          notification.info({ message: 'Upload cancelled' });
        },
      });
    };
  
    reader.readAsBinaryString(file);
  };
  
  // This state holds the preview data with the correct type
  const [filePreview, setFilePreview] = useState<FilePreview | null>(null);
  
  const proceedWithUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('model', model_name || '');
  
    try {
      const headers = getAuthToken() as AuthTokenType;
      const response: AxiosResponse = await axios.post(masterdatauploadURL, formData, headers );
  
      if (response) {
        notification.success({ message: 'File uploaded successfully' });
        window.location.reload();
      } else {
        notification.error({ message: 'Failed to upload file' });
      }
    } catch (error) {
      notification.error({ message: 'Failed to upload file' });
    }
  };

  useEffect(() => {
    if (dataSource && columns && includeFilters) {
      const newColumns = columns.map((column) => {
        const uniqueValues = Array.from(
          new Set(dataSource.map((data) => data[column.dataIndex as string]))
        );
        const filters = uniqueValues.map((value) => ({
          text: value,
          value,
        }));
        return {
          ...column,
          filters,
          onFilter: (value: any, record: any) =>
            record[column.dataIndex as string] === value,
          width: column.width || 150,
        };
      });
      
      setFilteredColumns(newColumns as any);
    }
    
  }, [dataSource, columns, includeFilters]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = dataSource.filter((record) =>
    Object.values(record).some((value) =>
      value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Handle CSV Export
  const handleExportToCSV = () => {
    // Convert data to CSV format
    const csvContent = columns.map(column => column.title).join('\t') + '\n' +
      filteredData.map(row => columns.map(column => row[column.dataIndex as string]).join('\t')).join('\n');
  
    // Create a Blob object containing the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
  
    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
  
    // Append the link to the document body and trigger the click event
    document.body.appendChild(link);
    link.click();
  
    // Clean up by revoking the temporary URL
    window.URL.revokeObjectURL(url);
  };


  
  return (
    <>
      <div className="card">
      <div className="borderline">

      <div className="cardHeader">
      <h2 className="headContent">{pageTitle}</h2>
          </div>

          <div className="rightContent">
            <Input
            className="customInput"
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '10px', width: "200px",height: "40px", }}
            />
            <Button className="bbutton" onClick={onAddRow}>{buttontitle}</Button>
            <Button className="bbutton" onClick={handleExportToCSV}>Download</Button>
            {model_name &&
              <Upload
                beforeUpload={file => {
                  handleUpload(file);
                  return false;
                }}
                showUploadList={false}
              >
                <Button>Upload</Button>
              </Upload>
            }
             <Button
             className="bbutton"
            onClick={handleDeleteSelected}
            disabled={selectedRowKeys.length === 0}
            danger
          >
            Delete Selected
          </Button>

          </div>
          
          {/* Plus sign button positioned at the top-right */}
        <br />
        <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
        <Table
         rowSelection={rowSelection}
          className="custom-table"
          dataSource={filteredData}
          columns={filteredColumns}
          pagination={{ pageSize: 20 }}
          size="small"
          loading={fetching}
          tableLayout="fixed" // Fixed table layout
          rowKey="id"
        />
        </div>
      </div>
      </div>
    </>
  );
};

export default ContentLayoutnew;
