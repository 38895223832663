import React, { useState } from "react";
import { Drawer, Table, Tag, Button, notification } from "antd";
import axios from "axios";
import { getAuthToken } from "../utils/functions";
import { AuthTokenType } from "../utils/types";
import { pgpprojectfileuploadurl } from "../utils/network";

interface File {
  id: number;
  filename: string;
  file_type: string | null;
  status: string;
  file: string | null; // File URL
  created_at: string;
  status_logs: { timestamp: string; status: string }[]; // JSON logs
}

interface Project {
  id: number;
  name: string;
  files: File[];
}

interface ProjectFileViewerProps {
  visible: boolean;
  onClose: () => void;
  selectedProject: Project | null;
  fetchUpdatedProject: () => Promise<void>; // Function to refresh project data
}


const ProjectFileViewer: React.FC<ProjectFileViewerProps> = ({
  visible,
  onClose,
  selectedProject,
  fetchUpdatedProject,
}) => {

  const [loadingFileId, setLoadingFileId] = useState<number | null>(null);

  const handleUpdateStatus = async (fileId: number, newStatus: string) => {
    setLoadingFileId(fileId);

    try {
      const headers = getAuthToken() as AuthTokenType; // ✅ Using your provided headers
      const response = await axios.patch(
        `${pgpprojectfileuploadurl}${fileId}/`,
        { status: newStatus }, 
        headers
      );

      if (response.status === 200) {
        await fetchUpdatedProject();
        notification.success({ message: `File status updated to "${newStatus}".` });
      } else {
        throw new Error("Unexpected response");
      }
    } catch (error) {
      notification.error({ message: `Failed to update file status.` });
    } finally {
      setLoadingFileId(null);
    }
  };

  const columns = [
    {
      title: "Filename",
      dataIndex: "filename",
      key: "filename",
      render: (text: string, record: File) => (
        <a href={record.file || "#"} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        const statusColors: Record<string, { backgroundColor: string; color: string }> = {
          Pending: { backgroundColor: "goldenrod", color: "black" },
          Uploaded: { backgroundColor: "royalblue", color: "white" },
          Incorrect: { backgroundColor: "crimson", color: "white" },
          Okay: { backgroundColor: "forestgreen", color: "white" },
        };

        const style = statusColors[status] || { backgroundColor: "gray", color: "white" };

        return <Tag style={style}>{status}</Tag>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: string) => new Date(date).toLocaleString(),
    },
    {
      title: "Status Logs",
      dataIndex: "status_logs",
      key: "status_logs",
      render: (logs: { timestamp: string; status: string }[]) =>
        logs.length > 0 ? (
          <ul>
            {logs.map((log, index) => (
              <li key={index}>
                <strong>{log.status}</strong> at {new Date(log.timestamp).toLocaleString()}
              </li>
            ))}
          </ul>
        ) : (
          "No logs"
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: File) => {
        const buttons = [];
    
        if (record.status !== "Okay") {
          buttons.push(
            <Button
              key="okay"
              type="primary"
              style={{ backgroundColor: "forestgreen", color: "white", marginRight: 8 }}
              onClick={() => handleUpdateStatus(record.id, "Okay")}
              loading={loadingFileId === record.id}
            >
              Okay
            </Button>
          );
        }
    
        if (record.status !== "Incorrect") {
          buttons.push(
            <Button
              key="incorrect"
              type="primary"
              danger
              onClick={() => handleUpdateStatus(record.id, "Incorrect")}
              loading={loadingFileId === record.id}
            >
              Incorrect
            </Button>
          );
        }
    
        return buttons.length > 0 ? <>{buttons}</> : null;
      },
    }
  ];

  return (
    <Drawer title={`Project Files - ${selectedProject?.name}`} width={900} visible={visible} onClose={onClose}>
      <Table columns={columns} dataSource={selectedProject?.files} rowKey="id" />
    </Drawer>
  );
};

export default ProjectFileViewer;
