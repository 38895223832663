import React, { useState } from 'react';
import { getAuthToken } from '../utils/functions';
import { AuthTokenType } from '../utils/types';
import axios from 'axios';
import { pgpprojectfileuploadurl } from '../utils/network';
import { notification, Modal, Button, Upload, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ProjectFileViewer from './ProjectFileViewer';
import ProjectFileTable from './ProjectFileTable';

const { Title, Text } = Typography;

interface FileData {
  id: number;
  filename: string;
  status: 'Pending' | 'Uploaded' | 'Incorrect' | 'Okay';
}

const PMGD = ({ selectedProject, fetchUpdatedProject }: { selectedProject: any, fetchUpdatedProject: () => void }) => {
  const [file, setFile] = useState<File | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState('');
  const [currentBox, setCurrentBox] = useState('');
  const [fileId, setFileId] = useState<number | null>(null);
  const [showUpload, setShowUpload] = useState(false);
  // Open modal and set file ID based on filename





  
  const openUploadSection = (section: string, box: string) => {
    setCurrentSection(section);
    setCurrentBox(box);
    setShowUpload(true);
    // Find file ID from the existing list
    const fileEntry = selectedProject?.files?.find((file: FileData) => file.filename === box);
    if (fileEntry) {
      setFileId(fileEntry.id);
    } else {
      setFileId(null);
    }

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setFile(null);
    setFileId(null);
    setIsModalOpen(false);
  };

  const handleFileChange = (info: any) => {
    setFile(info.fileList[0]?.originFileObj || null);
  };

  const handleFileUpload = async () => {
    if (!file) {
      notification.error({ message: 'Please select a file before uploading.' });
      return;
    }

    if (!fileId) {
      notification.error({ message: 'File entry not found in backend. Please check again.' });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append("project_id", selectedProject.id);
    formData.append('status', 'Uploaded'); // Updating status to "Uploaded"

    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.patch(`${pgpprojectfileuploadurl}${fileId}/`, formData, headers);

      if (response.status === 200) {
        await fetchUpdatedProject();
        
        notification.success({ message: `File updated successfully for ${currentSection} - ${currentBox}` });
        closeModal();
      } else {
        throw new Error('Unexpected response');
      }
    } catch (error) {
      notification.error({ message: `Failed to update file for ${currentSection} - ${currentBox}` });
    }
  };

  // Function to get file status and apply color
  const getFileStatus = (box: string) => {
    const fileData = selectedProject?.files?.find((file: FileData) => file.filename === box);
    if (!fileData) return { backgroundColor: 'white' };

    switch (fileData.status) {
      case 'Pending': return { backgroundColor: 'goldenrod', color: 'black' };
      case 'Uploaded': return { backgroundColor: 'royalblue', color: 'white' };
      case 'Incorrect': return { backgroundColor: 'crimson', color: 'white' };
      case 'Okay': return { backgroundColor: 'forestgreen', color: 'white' };
      default: return { backgroundColor: 'lightgray', color: 'black' };
    }
  };

  const renderSection = (section: string, rows: string[][]) => {
    return rows.map((row, rowIndex) => (
      <div key={rowIndex} style={{ display: 'flex', gap: '10px', flexDirection: 'row', width: '100%' }}>
        {row.map((box, boxIndex) => (
          <div
            key={boxIndex}
            style={{
              padding: '8px',
              border: '1px solid #ccc',
              margin: '5px',
              cursor: 'pointer',
              textAlign: 'center',
              width: row.length === 1 ? '100%' : '33%',
              boxSizing: 'border-box',
              ...getFileStatus(box),
            }}
            onClick={() => openUploadSection(section, box)}          >
            {box}
          </div>
        ))}
      </div>
    ));
  };

  const section1Rows = [
    ['SCR', 'F4', 'F5'],
    ['F6', 'F7', 'F8'],
    ['F9', 'F10', 'F11'],
    ['F12', 'F13', 'F14'],
    ['F15', 'F16', 'F17'],
    ['F18', 'F19', 'F20'],
    ['F21', 'TE-1/TE-7', 'CESIÓN ICC'],
    ['Contrato de Conexión'],
    ['Convenio de Operación'],
    ['Actualización Estudios'],
    ['ECC', 'EMI', 'ECAP'],
  ];

  const section2aRows = [
    ['ICC / Carta de actualización de costos'], ['Descripción Refuerzos'], ['Contrato de Obras'], ['Pago Refuerzos'], ['Permisos / Servidumbre'], ['Construcción'], ['Carta término de refuerzos'],
  ];

  const section2bRows = [
    ['Ingeniería Línea'], ['AS BUILT'],
  ];

  const section3Rows = [
    ['Documentos Legales'], ['Cronograma'], ['Autorización de Conexión'], ['RCA'], ['IFC'], ['OC'], ['Título habilitante de uso terreno'], ['Declaración Jurada'], ['Comprobante Pago Refuerzos'], ['Información Técnica'], ['Resolución DUC'],
  ];

  const section4Rows = [
    ['Aviso Interconexión'], ['Catastro Legal'], ['Proceso de Conexión'], ['Solicitud PES'], ['Pruebas PMRTE'], ['Autorización PES'], ['Notificación Fecha de Conexión'], ['Notificación F21'], ['Solicitud Entrada en Operación'], ['Carta Autorización Entrada en Operación'],
  ];

  return (
    <div>
    <div className='card' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div className="cardHeader">
        <h2 className="headContent">PMGD</h2>
      </div>

      <div style={{ display: 'flex', gap: '20px', flexDirection: 'row', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
          <h3>Distribuidora</h3>
          {renderSection('Section 1', section1Rows)}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
            <h3>Refuerzos</h3>
            {renderSection('Section 2a', section2aRows)}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
            <h3>Ingeniería de Línea</h3>
            {renderSection('Section 2b', section2bRows)}
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
          <h3>DUC</h3>
          {renderSection('Section 3', section3Rows)}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
          <h3>CEN</h3>
          {renderSection('Section 4', section4Rows)}
        </div>
      </div>
      </div>
      <div>
      {showUpload && (
 <div className='card' style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
 <h3>Upload File for {currentSection} - {currentBox}</h3>
 <Upload beforeUpload={() => false} onChange={handleFileChange} maxCount={1}>
   <Button icon={<UploadOutlined />}>Select File</Button>
 </Upload>
 <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
   <Button type="primary" onClick={handleFileUpload}>Upload</Button>
 </div>
 </div>
      )}
      <div className="card"  style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Title level={3} className="card-title">Project Details</Title>
        <div className="card-grid">
          <Text><strong>ID:</strong> {selectedProject.id}</Text>
          <Text><strong>Name:</strong> {selectedProject.name}</Text>
          <Text><strong>Code:</strong> {selectedProject.code}</Text>
          <Text><strong>Type:</strong> {selectedProject.project_type}</Text>
          <Text><strong>Technology:</strong> {selectedProject.technology}</Text>
          <Text><strong>PES Date:</strong> {selectedProject.pes_date}</Text>
          <Text><strong>Substation:</strong> {selectedProject.substation}</Text>
          <Text><strong>Feeder:</strong> {selectedProject.feeder}</Text>
          <Text><strong>Distribution Company:</strong> {selectedProject.distribution_company}</Text>
          <Text><strong>Power (MW):</strong> {selectedProject.power_mw}</Text>
          <Text><strong>Num POC:</strong> {selectedProject.num_poc}</Text>
          <Text><strong>SPV:</strong> {selectedProject.spv}</Text>
        </div>
      </div>

      <div className="card"  style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <ProjectFileTable selectedProject={selectedProject} />
      </div>

    
    </div>
    </div>
  );
};

export default PMGD;
